import { ContactInfo } from '../../types/contact';

export const mebleContactInfo: ContactInfo = {
    email: 'kontakt@meblesukces.pl',
    phone: '535 664 095',
    address: {
        street: 'Okrężna 1',
        postalCode: '66-500',
        city: 'Strzelce Kraj.',
    },
    openingHours: [
        {
            period: 'Pon. - Pt.',
            from: '10:00',
            to: '17:00',
        },
        {
            period: 'Sob.',
            from: '09:00',
            to: '13:00',
        },
    ],
    links: {
        facebook: {
            label: 'Meble na Facebooku',
            url: 'https://www.facebook.com/meblesukces',
        },
        googleMaps: {
            label: 'Zobacz na mapie',
            url:
                'https://www.google.com/maps/place/Meble+Sukces/@52.877677,15.5272533,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x54907e5df364ccf!8m2!3d52.877677!4d15.529442',
        },
    },
}
