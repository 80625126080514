import { graphql } from 'gatsby';
import * as React from 'react';
import { mebleContactInfo } from '../../data/meble-contact';
import { mebleContent } from '../../data/meble-content';
import {
    Banner,
    Content,
    Footer,
    Layout,
    Gallery,
} from '../../../common/components'

const mebleSukcesLogo = require('../images/meble-sukces.png')
const banner = require('../images/banner.jpg')
const bannerBorder = require('../images/banner-border.png')

const galleryImage1 = require('../gallery/budynek.jpg')
const galleryImage2 = require('../gallery/sypialnia.jpg')
const galleryImage3 = require('../gallery/kuchnia.jpg')
const galleryImage4 = require('../gallery/kanapy.jpg')
const galleryImage5 = require('../gallery/kanapy2.jpg')
const galleryImage6 = require('../gallery/kanapy3.jpg')

interface IndexPageProps {
    data: {
        site: {
            siteMetadata: {
                brand?: string
                title?: string
                description?: string
            }
        }
    }
}

const IndexPage: React.SFC<IndexPageProps> = ({
    data: {
        site: {
            siteMetadata: { brand, title, description },
        },
    },
}) => (
    <Layout pageTitle={title || 'Sukces'} pageDescription={description || ''}>
        <Banner
            companyName={brand || 'Sukces'}
            logo={mebleSukcesLogo}
            background="#F9F9F6"
            image={banner}
            imageBorder={bannerBorder}
            text={{
                headline: {
                    strong: 'Gotowi',
                    regular: 'na zakupy?',
                    color: 'sukces',
                },
                subline: 'Oni już czekają na swoje zamówienie!',
            }}
        />

        <Content
            short={mebleContent.short}
            long={mebleContent.long}
            color="sukces"
        />

        <Gallery
            images={[
                galleryImage1,
                galleryImage2,
                galleryImage3,
                galleryImage4,
                galleryImage6,
            ]}
            mobileImages={[galleryImage1, galleryImage3, galleryImage4]}
        />

        <Footer contact={mebleContactInfo} otherLink="bike" />
    </Layout>
)

export const query = graphql`
    query GetIndexPageData {
        site {
            siteMetadata {
                brand
                title
                description
            }
        }
    }
`

export default IndexPage
